import { render, staticRenderFns } from "./TruckDashboard.vue?vue&type=template&id=239eee68&"
import script from "./TruckDashboard.vue?vue&type=script&lang=js&"
export * from "./TruckDashboard.vue?vue&type=script&lang=js&"
import style0 from "./TruckDashboard.vue?vue&type=style&index=0&id=239eee68&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports