import { render, staticRenderFns } from "./TruckOnlyDetail.vue?vue&type=template&id=73fd00cc&scoped=true&"
import script from "./TruckOnlyDetail.vue?vue&type=script&lang=js&"
export * from "./TruckOnlyDetail.vue?vue&type=script&lang=js&"
import style0 from "./TruckOnlyDetail.vue?vue&type=style&index=0&id=73fd00cc&prod&lang=css&"
import style1 from "./TruckOnlyDetail.vue?vue&type=style&index=1&id=73fd00cc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73fd00cc",
  null
  
)

export default component.exports